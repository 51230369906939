import { navigate } from 'gatsby';
import { isValidElement, ReactNode } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  PatientFrontmatterSubnavItem,
  PatientPageFrontmatter,
} from '~/layouts/patient/patient-types';

export const isSSG = typeof window === 'undefined';

/**
 * Use this instead of `navigate` to avoid build problems with SSG
 *
 * @param pathname e.g. '/pagename'
 * @param replace pass true for redirect
 */
export const navigateSafe = (pathname: string, replace = false) => {
  !isSSG && navigate(pathname, { replace });
};

// -- Path and Hash Utils --

export const stripTrailingSlash = (path?: string) =>
  path ? path.replace(/\/$/, '') : path;

export const appendSlash = (path?: string) =>
  path ? `${stripTrailingSlash(path)}/` : path;

export const stripHash = (hash?: string) =>
  hash ? hash.replace(/^#/, '') : hash;

export const prependHash = (hash?: string) =>
  hash ? `#${stripHash(hash)}` : hash;

/** The opposite of Gatsby's withPrefix(),  this removes leading /patient/ or /hcp/ */
export const withoutPrefix = (path: string) =>
  path.replace(/^\/hcp/, '').replace(/^\/patient/, '');

// -- Path Matchers --

export const pathsMatch = (pathA?: string, pathB?: string) =>
  !!pathA && stripTrailingSlash(pathA) === stripTrailingSlash(pathB);

export const hashesMatch = (hashA?: string, hashB?: string) =>
  (!hashA && !hashB) || stripHash(hashA) === stripHash(hashB);

export const pathsMatchWithAnyHash = (
  path?: string,
  currentPath?: string,
  currentHash?: string,
) => !!path && !!currentHash && pathsMatch(path, currentPath);

export const pathsAndHashesMatch = (
  path?: string,
  currentPath?: string,
  hash?: string,
  currentHash?: string,
) => pathsMatch(path, currentPath) && hashesMatch(hash, currentHash);

export const isOnSubpage = (
  subnav: PatientFrontmatterSubnavItem[],
  path?: string,
  currentPath?: string,
  currentHash?: string,
) =>
  pathsMatchWithAnyHash(path, currentPath, currentHash)
    ? subnav.some((item) => hashesMatch(currentHash, item.slug))
    : false;

// -- Generators --

export const makePathWithHash = (path?: string, hash?: string) =>
  path ? appendSlash(path) + (prependHash(hash) ?? '') : path;

// -- Active Selection Helpers --

type PageData = PatientPageFrontmatter;
type SubpageData = PatientFrontmatterSubnavItem;

export const getPageAndSubpageData = (
  data: PageData | PageData[],
  path?: string,
  hash?: string,
): {
  pageData?: PageData;
  subpageData?: SubpageData;
} => {
  const dataArr = data && !Array.isArray(data) ? [data] : (data as PageData[]);
  const pageData = dataArr?.find((item) => pathsMatch(path, item.path));
  return {
    pageData,
    subpageData: pageData?.subnav?.find((item) => hashesMatch(hash, item.slug)),
  };
};

export const arePageAndSubpageActive = (
  data: PageData | PageData[],
  currentPath?: string,
  currentHash?: string,
) => {
  const { pageData, subpageData } = getPageAndSubpageData(
    data,
    currentPath,
    currentHash,
  );
  return {
    pageIsActive: !!pageData,
    subpageIsActive: !!subpageData,
  };
};

// --

export const randomHex = () => {
  const hex = ((Math.random() * 0xffffff) << 0).toString(16);
  return hex.padEnd(6, hex.slice(-1));
};

/**
 * For cases where all you have to generate a JSX key is a piece of
 * body text or something else that might contain non-alpha characters
 *
 * @param obj A string, ReactNode, or unique chunk of data
 */
export const makeKey = (obj: string | ReactNode | unknown) => {
  let key: string | undefined = undefined;

  if (
    typeof obj === 'object' ||
    typeof obj === 'function' ||
    typeof obj === 'string' ||
    typeof obj === 'boolean' ||
    typeof obj === 'bigint' ||
    typeof obj === 'number' ||
    typeof obj === 'symbol'
  ) {
    if (isValidElement(obj)) {
      key = renderToStaticMarkup(obj);
    } else {
      key = JSON.stringify(obj);
    }
  }

  return (
    key?.replace(/[^a-zA-Z]/g, '').slice(0, 100) ??
    // fallback: this is not desired, it will cause the element to always
    // rerender -- try to be sure you're passing in something usable!
    randomHex()
  );
};

export * from './staticAssetPath';
export * from './subnav';
