export type TabLines = {
  left: boolean;
  right: boolean;
};
export const getTabLines = (
  index: number,
  selectedIndex: number,
  totalTabs: number,
): TabLines => {
  // If the current tab is selected, no lines are needed
  if (index === selectedIndex) {
    return { left: false, right: false };
  }
  // If the current tab is right before the selected tab, no right line is needed
  if (index === selectedIndex - 1) {
    return { left: true, right: false };
  }
  // If the current tab is right after the selected tab, no left line is needed
  if (index === selectedIndex + 1) {
    return { left: false, right: true };
  }
  // For all other tabs, both lines are needed
  return { left: true, right: true };
};
