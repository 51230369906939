import { VideoModalItem } from '~/_shared/components/VideoModal/video-modal-types';
import { staticAssetPath } from '~/utils';

// by only typing the values, we get full inference on the keys
// DO NOT RENAME KEYS! They're tied to redirects/deeplinks and button ids
export const patientVideoData = {
  'mikes-journey': <VideoModalItem>{
    mp4: staticAssetPath('/video/patient/my-tomorrow/my-tomorrow-mike.mp4'),
    webm: staticAssetPath('/video/patient/my-tomorrow/my-tomorrow-mike.webm'),
    poster: staticAssetPath(
      '/video/patient/my-tomorrow/images/mike-interview-shot.png',
    ),
    captions: {
      src: staticAssetPath(
        '/video/patient/my-tomorrow/my-tomorrow-captions-en.vtt',
      ),
    },
    autoplay: true,
  },
  'kathys-journey': <VideoModalItem>{
    mp4: staticAssetPath('/video/patient/kathys-journey/my-tomorrow-kathy.mp4'),
    webm: staticAssetPath(
      '/video/patient/kathys-journey/my-tomorrow-kathy.webm',
    ),
    poster: staticAssetPath(
      '/video/patient/kathys-journey/images/kathy-poster.png',
    ), // jpg didn't render correctly so using png
    captions: {
      src: staticAssetPath(
        '/video/patient/kathys-journey/my-tomorrow-kathy-captions-en.vtt',
      ),
    },
    autoplay: true,
  },
  mode: <VideoModalItem>{
    mp4: staticAssetPath('/video/hcp/mode/MODe_FINAL_RTgel_SEP2023-V03C.mp4'),
    webm: staticAssetPath('/video/hcp/mode/MODe_FINAL_RTgel_SEP2023-V03C.webm'),
    poster: staticAssetPath('/video/hcp/mode/images/mode-poster.jpg'),
    captions: {
      src: staticAssetPath(
        '/video/hcp/mode/captions/mechanism-of-delivery-OCT2022.vtt',
      ),
    },
  },
};
