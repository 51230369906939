import { createContext, FC, useCallback, useEffect, useState } from 'react';
import { VideoModalItem } from '~/_shared/components/VideoModal';
import { navigateSafe, withoutPrefix } from '~/utils';
import {
  modalVideoByKey,
  parseVideoDeeplink,
  VideoModalKey,
} from '~/utils/modal-video-lookup';
import { ChildrenProps } from '../config/types';

type VideoModalContextValue = {
  videoModalData: VideoModalItem | null;
  videoModalKey: VideoModalKey | null;
  openVideoModal: (key: VideoModalKey, autoplay?: boolean) => void;
  closeVideoModal: () => void;
  isVideoModalOpen: boolean;
};

export const VideoModalContext = createContext<VideoModalContextValue>({
  videoModalData: null,
  videoModalKey: null,
  openVideoModal: (key: VideoModalKey, autoplay?: boolean) => {},
  closeVideoModal: () => {},
  isVideoModalOpen: false,
});

export const VideoModalContextProvider: FC<ChildrenProps> = ({ children }) => {
  const [videoModalData, setVideoModalData] = useState<VideoModalItem | null>(
    null,
  );
  const [videoModalKey, setVideoModalKey] = useState<VideoModalKey | null>(
    null,
  );

  const openVideoModal = useCallback((key: VideoModalKey, autoplay = false) => {
    const item = modalVideoByKey(key);
    setVideoModalKey(key);
    setVideoModalData(item);
  }, []);

  const closeVideoModal = useCallback(() => {
    setVideoModalKey(null);
    setVideoModalData(null);
  }, []);

  useEffect(
    () => {
      const initialKey = parseVideoDeeplink();
      if (initialKey) {
        openVideoModal(initialKey);

        // Collapse the video-modal query out of the url using `replace`. If there's not a link on the page,
        // the video is not immediately re-openable, which is by design at this time.
        // Tiny delay to allow `no-confirm` and other querystring parsing to complete on landing first.
        const delay = setTimeout(() => {
          navigateSafe(
            `${withoutPrefix(location.pathname)}${location?.hash}`,
            true,
          );
        }, 100);
        return () => clearTimeout(delay);
      }
    },
    // Once only - openVideoModal won't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <VideoModalContext.Provider
      value={{
        videoModalData,
        videoModalKey,
        openVideoModal,
        closeVideoModal,
        isVideoModalOpen: !!videoModalData,
      }}
    >
      {children}
    </VideoModalContext.Provider>
  );
};
