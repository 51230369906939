exports.components = {
  "component---src-pages-about-jelmyto-tsx": () => import("./../../../src/pages/about-jelmyto.tsx" /* webpackChunkName: "component---src-pages-about-jelmyto-tsx" */),
  "component---src-pages-about-utuc-tsx": () => import("./../../../src/pages/about-utuc.tsx" /* webpackChunkName: "component---src-pages-about-utuc-tsx" */),
  "component---src-pages-helpful-terms-tsx": () => import("./../../../src/pages/helpful-terms.tsx" /* webpackChunkName: "component---src-pages-helpful-terms-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-locations-tsx": () => import("./../../../src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-results-tsx": () => import("./../../../src/pages/results.tsx" /* webpackChunkName: "component---src-pages-results-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-treatment-plan-tsx": () => import("./../../../src/pages/treatment-plan.tsx" /* webpackChunkName: "component---src-pages-treatment-plan-tsx" */)
}

