/**
 * "You generally should implement the same components in both
 *  gatsby-ssr.js and gatsby-browser.js so that pages generated
 *  through SSR are the same after being hydrated in the browser."
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import type { GatsbyBrowser } from 'gatsby';
import React from 'react';
import { VideoModalContextProvider } from './src/_shared/context/VideoModalContext';
import './src/_shared/utils/polyfills';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => <VideoModalContextProvider>{element}</VideoModalContextProvider>;
