import 'focus-visible/dist/focus-visible.min';
import 'intersection-observer';
import 'smoothscroll-anchor-polyfill';
import smoothscroll from 'smoothscroll-polyfill';
import { isSSG } from '~/utils';

/* 
We removed async loading to avoid race conditions.

Each polyfill (besides focus-visible which is really a js utility) needs
to defer to newer native functionality if it exists:
- https://github.com/iamdustan/smoothscroll/blob/master/src/smoothscroll.js#L9 
- https://github.com/jonaskuske/smoothscroll-anchor-polyfill/blob/main/src/index.js#L32
- https://github.com/w3c/IntersectionObserver/blob/main/polyfill/intersection-observer.js#L17-L18
*/

if (!isSSG) {
  /* 
  Adds smooth scrolling support for Safari/mobile Safari < 12.2
  & Edge (version unknown - 2020-ish?)
  
  smoothscroll-anchor-polyfill requires smoothscroll-polyfill and this css,
  which can't be set here using javascript
  
  html {
    scroll-behavior: smooth;
    --scroll-behavior: smooth;
  }
  */
  smoothscroll.polyfill();
}
