import { patientVideoData } from '~/config-patient';
import { VideoModalItem } from '~/_shared/components/VideoModal';
import { isSSG } from '.';

// Provides inferred type for the context, which provides code hinting when calling openVideoModal
export type VideoModalKey =
  | keyof typeof patientVideoData;

/**
 * Internal - you shouldn't need to access video data directly, call the VideoModalContext
 * `openVideoModal(key: VideoModalKey)` method instead.
 */
export const modalVideoByKey = (key: VideoModalKey): VideoModalItem | null =>
  patientVideoData[key] ?? null;

/**
 * App-specific parsing of the `video-modal` querystring and related fallback cases
 *
 * @returns VideoModalKey (string) | undefined
 */
export const parseVideoDeeplink = (): VideoModalKey | undefined => {
  if (isSSG) {
    return;
  }

  const params = new URLSearchParams(window.location.search);

  // Legacy one-off param from 6/2022 before a system was defined for this
  // (reference: https://github.com/yallllc/jel004/pull/237)
  if (params.has('delivery-video')) {
    return 'mode';
  }

  // Standard System: redirects should include key (and may pass `no-confirm` for hcp links to bypass provider alert)
  // e.g. /speaker -> /hcp/?video-modal=speaker&no-confirm
  if (params.has('video-modal')) {
    const key = params.get('video-modal') ?? undefined;

    // Legacy case for a specific not-redirected url without a key that was sent out
    // (https://jelmyto.com/patient/treatment-plan/?video-modal#receiving)
    if (
      (!key || key === 'my-tomorrow') &&
      window.location.hash === '#receiving'
    ) {
      return 'mikes-journey' as VideoModalKey;
    }

    return key as VideoModalKey;
  }
};
